<template>
	<b-container>
		<b-row class="a-section-title">
			<b-col>
				<h2 class="text-center">
					<!-- <span class="w-color-primary z-fancy-underline">
						{{ $t('Benefity') }}<svg-fancy-underline-icon />
						<img src="./img/benefits.png" class="a-small-logo" alt="">
					</span>, <span class="w-color-black">{{ $t('ktoré vám Zmudri prináša...') }}</span> -->
					<span class="w-color-black">
						<span class="w-color-primary z-fancy-underline">
							Prečo<svg-fancy-underline-icon /><img src="./img/benefits.png" class="a-small-logo" alt="">
						</span> používať Zmudri
					</span>
				</h2>
			</b-col>
		</b-row>
		<b-row align-h="center" class="benefits" :class="{ '-forTeachers': forTeachers, '-forStudents': !forTeachers }">
			<b-col class="benefit" v-for="(benefit, i) of benefitsData" :key="i" :xl="benefitsData.length > 3 ? '3' : '4'" lg="4" sm="6">
				<div class="img-wrapper text-center">
					<img :src="require(`${benefit.image}`)" />
				</div>
				<div :class="`line w-background-${benefit.color || 'primary'} mb-sm-5 mb-3`"></div>
				<h4 class="d-flex flex-column">
					<span :class="`w-color-${benefit.color || 'primary'}`">{{ benefit.title }}</span>
					<span class="mt-2">{{ benefit.subtitle }}</span>
				</h4>
				<p class="mt-4">{{ benefit.description }}</p>
			</b-col>
		</b-row>
	</b-container>
</template>

<script>
import benefits from './benefits.json'
import benefitsTeachers from './benefits-teachers.json'

export default {
	components: {
		// 'svg-fancy-underline-icon': () => import('@/plugins/appzmudri/_theme/icon/fancy-underline.svg?inline')
	},

	props: {
		forTeachers: {
			required: false,
			default: false
		}
	},

	data() {
		return {
			benefits,
			benefitsTeachers
		}
	},

	computed: {
		benefitsData() {
			return this.forTeachers ? this.benefitsTeachers : this.benefits
		}
	}
}
</script>

<style lang="scss" scoped>
.line {
	height: 5px;
	width: 33%;
	max-width: 118px;
}

.img-wrapper {
	width: 100%;
	padding-top: 100%;
	position: relative;

	img {
		position: absolute;
		height: auto;
		max-height: 262px;
		max-width: 100%;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}

	@media only screen and (max-width: 575px) {
		position: relative;
		margin-top: 2rem;
		padding-top: 300px;
	}
}

.benefits {
	&.-forTeachers {
		.benefit {
			&:nth-child(1) {
				.img-wrapper {
					img {
						transform: translate(-45%, -50%);
					}
				}
			}

			&:nth-child(2) {
				.img-wrapper {
					img {
						transform: translate(-50%, -60%);
					}
				}
			}
		}
	}

	&.-forStudents {
		@media only screen and (min-width: 992px) {
			justify-content: space-between !important;
		}
		max-width: 1336px;

		.benefit {
			max-width: 372px;

			&:nth-child(2) {
				.img-wrapper {
					img {
						transform: translate(-50%, -60%);
					}
				}
			}

			&:nth-child(3) {
				.img-wrapper {
					img {
						transform: translate(-40%, -50%);
					}
				}
			}
		}
	}
}
</style>
